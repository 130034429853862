import React, { useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import './App.css';
import UnderConstruction from './underConstruction/UnderConstruction';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: {
                    construction: {
                        title: 'FR knit and FR garments',
                        description: 'Website under renovation',
                        moreInfo: 'For information about our products',
                        phone: 'Phone',
                        email: 'Email'
                    }
                }
            },
            fr: {
                translation: {
                    construction: {
                        title: 'Tricots et vêtements ignifuges',
                        description: 'Site en rénovation',
                        moreInfo: 'Pour information conernant nos produits',
                        phone: 'Téléphone',
                        email: 'Courriel'
                    }
                }
            }
        },
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

function App() {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = '../'
    }, []);
    return (
        <>
            <UnderConstruction/>
            {/*<MainNav/>*/}
            {/*<div className="page js-page">*/}
            {/*    <Header/>*/}
            {/*    <BrowserRouter>*/}
            {/*        <Switch>*/}
            {/*            <Route path="/">*/}
            {/*                <Home/>*/}
            {/*            </Route>*/}
            {/*        </Switch>*/}
            {/*    </BrowserRouter>*/}
            {/*    <Footer/>*/}
            {/*</div>*/}
        </>
    );
}

export default App;
