import * as React from 'react';

import './UnderConstruction.scss';
import logo from '../img/logoConstruction.png';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';


function UnderConstruction() {
    const { t } = useTranslation();

    const onLanguageClick = async (lang: string) => {
        await i18next.changeLanguage(lang);
    };

    return (
        <div className="underConstructionContainer">
            <img className="logo" src={logo} alt="Logo compagnie"/>
            <h2>{t('construction.title')}</h2>
            <p>{t('construction.description')}</p>
            <p>{t('construction.moreInfo')}</p>
            <div className="bold">{t('construction.phone')}</div>
            <a href="tel:418-661-2952">418-661-2952</a>
            <a href="tel:888-811-2952">888-811-2952</a>
            <div className="bold mt-1">{t('construction.email')}</div>
            <a href="mailto:info@guillemotinc.com">info@guillemotinc.com</a>

            <button className={`${i18next.language === 'fr' ? 'hidden' : ''} main-button`}
                    onClick={() => onLanguageClick('fr')}>FR
            </button>
            <button className={`${i18next.language === 'en' ? 'hidden' : ''} main-button`}
                    onClick={() => onLanguageClick('en')}>EN
            </button>
        </div>
    );
}

export default UnderConstruction;
